import React from "react";
import PropTypes from "prop-types";

/* Elements Decoration Icons */

const TtsIcons = ({ type }) => {
  return type === "tts-logo" ? (
    <svg
      width="401"
      height="401"
      viewBox="0 0 401 401"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M352.419 213.142L291.658 183.178C282.876 178.846 272.118 182.487 267.787 191.27L239.679 248.271H263.241C276.926 248.271 288.064 259.409 288.064 273.094V296.68L306.676 305.867C315.458 310.198 326.216 306.557 330.547 297.775L360.511 237.037C364.843 228.231 361.202 217.473 352.419 213.142ZM283.637 220.948C278.639 218.473 276.569 212.428 279.044 207.43C281.519 202.432 287.564 200.361 292.562 202.836C297.56 205.312 299.631 211.357 297.156 216.355C294.681 221.353 288.635 223.423 283.637 220.948ZM294.704 253.578C289.706 251.103 287.636 245.058 290.111 240.06C292.586 235.062 298.631 232.991 303.629 235.466C308.627 237.941 310.698 243.987 308.223 248.985C305.748 253.983 299.702 256.053 294.704 253.578ZM320.361 284.804C317.886 289.802 311.84 291.872 306.842 289.397C301.844 286.922 299.774 280.877 302.249 275.879C304.724 270.881 310.769 268.81 315.767 271.285C320.789 273.737 322.836 279.782 320.361 284.804Z"
        fill="#80ED99"
      />
      <path
        d="M263.217 255.268H195.482C185.677 255.268 177.656 263.288 177.656 273.094V340.829C177.656 350.634 185.677 358.655 195.482 358.655H263.217C273.023 358.655 281.043 350.634 281.043 340.829V273.094C281.043 263.288 273.023 255.268 263.217 255.268ZM194.887 282.614C194.887 277.045 199.409 272.523 204.979 272.523C210.572 272.523 215.094 277.045 215.094 282.614C215.094 288.207 210.572 292.729 204.979 292.729C199.409 292.705 194.887 288.183 194.887 282.614ZM205.621 343.804C200.052 343.804 195.53 339.282 195.53 333.689C195.53 328.12 200.052 323.598 205.621 323.598C211.214 323.598 215.736 328.12 215.736 333.689C215.712 339.282 211.19 343.804 205.621 343.804ZM242.749 282.614C242.749 279.925 243.796 277.497 245.51 275.688C247.343 273.737 249.961 272.523 252.864 272.523C258.433 272.523 262.955 277.045 262.955 282.614C262.955 283.161 262.908 283.709 262.836 284.232C262.051 289.04 257.886 292.705 252.864 292.705C247.271 292.705 242.749 288.183 242.749 282.614ZM256.101 343.804C250.508 343.804 245.986 339.282 245.986 333.689C245.986 328.12 250.508 323.598 256.101 323.598C261.67 323.598 266.192 328.12 266.192 333.689C266.192 339.282 261.67 343.804 256.101 343.804Z"
        fill="#80ED99"
      />
      <path
        d="M192.127 142.211V131.557C192.127 122.5 185.208 115.581 176.152 115.581H74.9757C65.9194 115.581 59 122.5 59 131.557V184.806C59 193.323 65.9194 200.781 74.9757 200.781H176.152C185.208 200.781 192.127 193.323 192.127 184.806V174.152L229.401 200.781V115.581L192.127 142.211Z"
        fill="#80ED99"
      />
      <path
        d="M117.571 80.9636C117.571 94.2035 106.847 104.927 93.6071 104.927C80.3772 104.927 69.6436 94.2035 69.6436 80.9636C69.6436 67.7336 80.3772 57 93.6071 57C106.847 57 117.571 67.7336 117.571 80.9636Z"
        fill="#80ED99"
      />
      <path
        d="M181.474 80.9636C181.474 94.2035 170.75 104.927 157.51 104.927C144.281 104.927 133.547 94.2035 133.547 80.9636C133.547 67.7336 144.281 57 157.51 57C170.75 57 181.474 67.7336 181.474 80.9636Z"
        fill="#80ED99"
      />
      <rect
        x="8"
        y="8"
        width="385"
        height="385"
        rx="93"
        stroke="#80ED99"
        strokeWidth="16"
      />
    </svg>
  ) : type === "automatic-clips" ? (
    <svg
      fill="#80ED99"
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m87.98 11.238h-75.957c-3.8789 0.007813-7.0195 3.1484-7.0234 7.0234v63.477c0.003906 3.875 3.1445 7.0156 7.0195 7.0234h75.957c3.8789-0.007813 7.0195-3.1484 7.0234-7.0234v-63.477c-0.003906-3.875-3.1445-7.0156-7.0195-7.0234zm0 4c1.6641 0.003907 3.0195 1.3555 3.0195 3.0234v9.7266h-10.398v-12.75zm-20.582 35.805-30.273 17.48c-0.375 0.21484-0.83594 0.21484-1.207 0-0.375-0.21484-0.60547-0.61328-0.60547-1.043v-34.961c0-0.42969 0.23047-0.82812 0.60547-1.043 0.37109-0.21484 0.83203-0.21484 1.207 0l30.273 17.48c0.37109 0.21484 0.60156 0.61328 0.60156 1.043s-0.23047 0.82812-0.60156 1.043zm-48-4.0352h-10.398v-13.035h10.398zm0 5.9844v13.035h-10.398v-13.035zm61.203 0h10.398v13.035h-10.398zm0-5.9844v-13.035h10.398v13.035zm-68.582-31.77h7.3789v12.75h-10.398v-9.7266c0-1.668 1.3555-3.0195 3.0195-3.0234zm0 69.52v0.003907c-1.6641-0.003907-3.0195-1.3555-3.0195-3.0234v-9.7266h10.398v12.75zm75.957 0h-7.3789l0.003906-12.746h10.398v9.7266c0 1.668-1.3555 3.0195-3.0195 3.0234z" />
    </svg>
  ) : type === "battlescribe" ? (
    <svg
      width="192"
      height="192"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 35.5001C46.9 31.9001 31.8333 26.6667 25 24.5C26.1667 28.6667 28.6 37.3 29 38.5C29.4 39.7 33.1667 46 35 49C36.5 49.5 40 50.7001 42 51.5C44 52.3 48.1667 52.5 50 52.5C47.6667 53.5 42.8 55.5 42 55.5H37.5C40.5 59.5 46.7 67.8 47.5 69C48.5 70.5 58 80.5 59 81.5C60 82.5 61 83 61.5 83.5C61.9 83.9 64.6667 84 66 84H76.5C75.3333 85 72.7 87.2 71.5 88C70.3 88.8 67.6667 89.3333 66.5 89.5L79 102C65.3333 116.167 37.9 144.6 37.5 145C37 145.5 36 146.5 35 148.5L34 150.5L24.5 168C30.6667 164.333 43.2 156.9 44 156.5C44.8 156.1 46.6667 154.333 47.5 153.5L90 113C98.8333 121.167 116.8 137.7 118 138.5C119.2 139.3 120.833 138.833 121.5 138.5H132.5C132 139 130.6 140.2 129 141C127.4 141.8 125.333 142.333 124.5 142.5C125.667 143.333 128.9 145.5 132.5 147.5C137 150 143.5 150.5 146 151.5C148 152.3 149.833 153.833 150.5 154.5C153.667 158.167 159.5 164.7 161.5 165.5C163.5 166.3 166.667 167.5 167.5 167.5C161 160.333 147.8 145.6 147 144C146 142 146.5 134 146 132.5C145.6 131.3 140.833 124.333 138.5 121C134.5 115.833 126.5 105.7 126.5 106.5C126.5 107.5 125 112 124.5 113C124.1 113.8 122.333 116.333 121.5 117.5V102C121.5 101.2 120.5 99.6667 120 99L112.5 91.5L136.5 69L145 79L149 75.5C147.667 73.8333 144.8 70.2 144 69C143.2 67.8 140 63.8334 138.5 62C143.833 55.5 154.8 42.5 156 42.5C157.5 42.5 163.5 42 165.5 40C167.5 38 170 31.5001 165.5 27.0001C161 22.5001 154 24.5001 151.5 27.0001C149.5 29.0001 149.333 33.8334 149.5 36.0001L129.5 53.5C127.333 51.6667 122.9 47.9 122.5 47.5C122.1 47.1 118.333 44.6667 116.5 43.5L112.5 47C113.333 47.5 115.7 49.1 118.5 51.5C121.3 53.9 122.667 55.1667 123 55.5L101 78.5L79 57C79 57.6667 78.8 59.6 78 62C77.2 64.4001 75.3333 66.6667 74.5 67.5V54C74.5 52 73.5 50.8334 73 50.5C68.5 47 58.1 39.1 52.5 35.5001Z"
        fill="#80ED99"
      />
      <rect
        x="4"
        y="4"
        width="184"
        height="184"
        rx="16"
        stroke="#80ED99"
        strokeWidth="8"
      />
    </svg>
  ) : type === "drawing-tools" ? (
    <svg
      fill="#80ED99"
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m68 17.699c2-3.3008 1-7.6992-2.3008-9.8008l-7-4.3008c-3.3008-2.1016-7.6992-1.1016-9.8008 2.3008l-25.699 41.203 19 11.898z" />
        <path d="m19.5 79.602 11.602-7.1016c2.3984-1.5 4.3008-3.3984 6-5.6016l-18.703-11.699c-1.3008 2.5-2.1992 5.1016-2.3984 7.8008l-1.3008 13.602c-0.30078 2.5977 2.6016 4.3984 4.8008 3z" />
        <path d="m85.102 90.199c-9.8008-11.699-20.602-6.3008-26.398-3.3008-1.3008 0.69922-3.3008 1.6992-3.8984 1.6992-1.8008 0-2.1992-0.5-3.6992-2.6016-1.8008-2.5-4.5-6.6016-10.898-6.3008-7 0-21.199 7.3008-24 8.8008-2.1992 1.1016-3 3.8008-1.8984 6 1.1016 2.1992 3.8008 3 6 1.8984 6.1016-3.1992 16.301-7.8008 19.898-7.8008 1.6992 0.19922 2 0.39844 3.6016 2.6016 1.8008 2.5 4.5 6.3984 11 6.3984 2.6992 0 5.1992-1.3008 8-2.6992 6.3984-3.1992 10.602-4.8984 15.5 1.1016 0.89844 1.1016 2.1016 1.6016 3.3984 1.6016 1 0 2-0.30078 2.8008-1 1.8906-1.6992 2.1914-4.4961 0.59375-6.3984z" />
      </g>
    </svg>
  ) : type === "effects" ? (
    <svg
      fill="#80ED99"
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m43.801 92.602s-8.1992 4.3984-19.898-4.3984c-14.699-11-2.1992-35.602 5.6992-38.301-2 2.1992-4.6992 9 1 11.398 2.6992 1.5 11.199-0.69922 9.1992-11.398-1-4.5-9-31.301 10.898-43.801-1.1992 2.5-5.5 9.6992 11.898 26.602 3 3.1992 22.602 19.898 19.898 37.602-1 6-9.3984 24.699-28.398 23.398 2.3984-1 19.199-13.398 5-31.801-0.39844 3.3984-4.1016 8.8008-9.1016 12.398-4.7969 3.6992-15.098 10.898-6.1953 18.301z" />
    </svg>
  ) : type === "health-bar" ? (
    <svg
      fill="#80ED99"
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m50 16.809c-14.699-14.906-39.555-7.75-42.004 14.703-2.4453 22.441 41.969 59.484 41.996 59.52h0.007812c0.035156-0.035156 44.449-37.074 42.004-59.52-2.4492-22.453-27.305-29.609-42.004-14.703zm26.355 28.098c0 4.0156-2.5977 7.2773-5.8047 7.2773h-41.102c-3.207 0-5.8047-3.2617-5.8047-7.2773l-0.003906-2.1172c0-4.0195 2.6016-7.2773 5.8047-7.2773h41.105c3.207 0 5.8047 3.2539 5.8047 7.2773l0.003906 2.1172z" />
        <path d="m52.898 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0.003906-0.90234-0.95312-1.6328-2.1328-1.6328z" />
        <path d="m47.102 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0-0.90234-0.95703-1.6328-2.1328-1.6328z" />
        <path d="m41.305 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1758 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0-0.90234-0.95703-1.6328-2.1328-1.6328z" />
        <path d="m35.504 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0.003906-0.90234-0.95312-1.6328-2.1328-1.6328z" />
        <path d="m29.707 38.895c-1.1758 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0-0.90234-0.95703-1.6328-2.1328-1.6328z" />
      </g>
    </svg>
  ) : type === "scoreboard" ? (
    <svg
      fill="#80ED99"
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m50 16.809c-14.699-14.906-39.555-7.75-42.004 14.703-2.4453 22.441 41.969 59.484 41.996 59.52h0.007812c0.035156-0.035156 44.449-37.074 42.004-59.52-2.4492-22.453-27.305-29.609-42.004-14.703zm26.355 28.098c0 4.0156-2.5977 7.2773-5.8047 7.2773h-41.102c-3.207 0-5.8047-3.2617-5.8047-7.2773l-0.003906-2.1172c0-4.0195 2.6016-7.2773 5.8047-7.2773h41.105c3.207 0 5.8047 3.2539 5.8047 7.2773l0.003906 2.1172z" />
        <path d="m52.898 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0.003906-0.90234-0.95312-1.6328-2.1328-1.6328z" />
        <path d="m47.102 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0-0.90234-0.95703-1.6328-2.1328-1.6328z" />
        <path d="m41.305 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1758 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0-0.90234-0.95703-1.6328-2.1328-1.6328z" />
        <path d="m35.504 38.895c-1.1797 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0.003906-0.90234-0.95312-1.6328-2.1328-1.6328z" />
        <path d="m29.707 38.895c-1.1758 0-2.1328 0.73047-2.1328 1.6328v6.6484c0 0.90234 0.95703 1.6328 2.1328 1.6328 1.1797 0 2.1328-0.73047 2.1328-1.6328v-6.6484c0-0.90234-0.95703-1.6328-2.1328-1.6328z" />
      </g>
    </svg>
  ) : (
    <></>
  );
};

TtsIcons.propTypes = {
  fill: PropTypes.string,
};

TtsIcons.defaultProps = {
  fill: "#80ED99",
};

export default TtsIcons;
