import React from "react";
import styled from "styled-components";
import { Container } from "./layoutComponents";
import TtsIcons from "./ttsIcons";

const Footer = ({ className }) => (
  <footer className={className}>
    <Container>
      <FooterContent>
        <LogoWrapper>
          <TtsIcons type="tts-logo" />
        </LogoWrapper>
        <h5>
          Raptors Intercessor image courtesy of
          <a
            href="https://www.themightybrush.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Mighty Brush.
          </a>
          <br />
          Tabletop Streamer is not any way affiliated with or endorsed by Games
          Workshop Limited.
        </h5>

        <p>© {new Date().getFullYear()}, Tabletop Streamer</p>
      </FooterContent>
    </Container>
  </footer>
);

const StyledFooter = styled(Footer)`
  color: #fffaff;
  background: var(--primary-color);
  padding: 1rem 0;
  text-align: center;
  margin-top: 100px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
  @media (max-width: 470px) {
    margin-top: 25px;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto 40px;

  p {
    color: #181818;
    font-family: IBM Plex Mono;
    font-size: 14px;
    margin-top: 40px;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 1.6;
  }
  h5 {
    color: var(--primary-font);
    font-weight: 200;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1.5;
    font-family: IBM Plex Mono;
  }
  a {
    text-decoration: underline;
    margin-left: 6px;
    font-family: IBM Plex Mono;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 80px;

  svg {
    width: 80px;
    height: 80px;
  }
`;

export default StyledFooter;
